import React from 'react';

import imgC from '../../assets/image/services/ocorie-srv-splash-cert-img.png';
import imgDP from '../../assets/image/dots/dot-pattern-black.png';

const Content = () => {
  const contentWidgetData = [
    {
      title: 'Certified Ethical Hacker (CEH)'
    },
    {
      title: 'CompTIA Security +'
    },
    {
      title: 'CompTIA Network +'
    },
    {
      title: 'PEBC ISO 27001 Lead Implementer'
    },
    {
      title: 'Certified Information Security Manager (CISM)'
    },
    {
      title: 'Certified Information Systems Auditor (CISA)'
    },
    {
      title: 'Certified Cloud Security Knowledge (CCSK)'
    },
    {
      title: 'Certified Computer Networking Associate (CCNA)'
    },
    {
      title: 'Microsoft Certified Professional'
    },
    {
      title: 'Cisco Certified Network Professional (CNNP)'
    },
    {
      title: 'Certified VMware Professional'
    }
  ];
  return (
    <div className="pt-14 pt-md-19 pt-lg-20 pb-15 pb-md-18 pb-lg-18 mb-lg-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div
            className="col-xl-6 col-lg-7 col-md-7 col-xs-10"
            data-aos="fade-right"
            data-aos-delay={300}
          >
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Certification Courses
            </h2>
            <p className="font-size-6 pr-xl-13">
              Our courses are developed by industry leaders in numerous fields such as: 
            </p>
            <ul className="list-unstyled mt-5">
              {contentWidgetData.map(({ title }, index) => {
                return (
                  <li className="align-items-center mb-0" key={index}>
                    <div className="content">
                      <p className="font-size-6 text-default-color-2 mb-0">{title}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
            <p className="font-size-6 pr-xl-13">
              Ocorie Systems has a robust framework to screen the best and brightest IT talent, out of a pool of trained and highly skilled Cybersecurity professionals for clients.
            </p>
          </div>
          {/* Right End */}
          {/* Left */}
          <div className="col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgC}
                  alt=""
                  data-aos="fade-left"
                  data-aos-duration={200}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration={300}
                    data-aos-delay={100}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Left End */}
        </div>
      </div>
    </div>
  );
};

export default Content;
